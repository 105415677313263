
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import HomeLayout from '@/app/layouts/HomeLayout.vue';
import ProductFilter from '@/components/ProductFilter.vue';
import BannerSlider from '@/components/BannerSlider.vue';
import ShopItem from '@/components/ShopItem.vue';
import ShopItemGroup from '@/components/ShopItemGroup.vue';
import Api from '@/module/api/service/Api';
import IProducts from '@/module/main/dto/IProducts';
import ICategory from '@/module/main/dto/ICategory';
import SearchResults from '@/components/SearchResults.vue';
import ProductsDefault from '@/module/main/dto/ProductsDefault';
import CategoryDefault from '@/module/main/dto/CategoryDefault';
import IBreadcrumb from '@/module/main/model/IBreadcrumb';
import CategoryBreadcrumb from '@/module/main/model/CategoryBreadcrumb';
import { mainBreadcrumb } from '@/module/main/model/MainBreadcrumb';
import router from '@/app/router';
import RouteName from '@/app/router/NameEnum';
import ProductPagePicker from '@/components/ProductPagePicker.vue';

const MOBILE_TOP_BAR_HEIGHT: number = 250;

enum CategoryTypeEnum {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  PROMO ='PROMO',
}

@Component({
  components: {
    SearchResults,
    ShopItemGroup,
    ShopItem,
    HomeLayout,
    BannerSlider,
    ProductFilter,
    ProductPagePicker,
  },
})
export default class Main extends Vue {
  private displayFilter = window.innerWidth > 991;
  private displayBanner = true;
  private products: IProducts = new ProductsDefault(); // from the category we are in
  private categoryData: ICategory = new CategoryDefault();
  private currentLoadedPage = 1;

  private isLoading = true;
  private isLoadingProducts = true;
  private searching = false;
  private searchResults: IProducts | null = null;
  private searchNotSuccessful = false;

  private sortFlag = 0;
  private filterData: any = null;
  private searchQuery = '';

  private windowWidth = window.innerWidth;

  private displayMainPage = true;

  private toggleFilter(): void {
    this.displayFilter = !this.displayFilter;
    if (window.innerWidth > 991) {
      window.scrollTo(0, 200);
    } else {
      window.scrollTo(0, 150);
    }
  }

  private getProducts(): void {
    if (!this.categoryData || !this.categoryData.id)
      router.push({ name: RouteName.MAIN });
    Api.products(this.categoryData.id, this.currentLoadedPage).then((response) => {
      if (response) {
        this.products = response;
        this.filterData = null;
        this.currentLoadedPage = this.currentLoadedPage ?? 1;
        this.isLoading = false;
        this.sortFlag = 0;
        this.isLoadingProducts = false;
        scroll(0,0);
      }
    }).catch(() => {
      router.push({ name: RouteName.MAIN });
    });
  }

  private isParentCategory(category: ICategory): boolean {
    return typeof category.parentCategoryId !== 'number'
      &&  (typeof category.types === 'undefined' || !category.types.includes('PROMO'));
  }

  private isSubcategory(category: ICategory): boolean {
    return typeof category.parentCategoryId === 'number';
  }

  private addParentBreadcrumb(crumbs: IBreadcrumb[]): void {
    const parentCategory = this.$store.state.categories.data.filter(
      (category: ICategory) => category.id === this.categoryData.parentCategoryId);

    if (parentCategory.length > 0) {
      crumbs.push(new CategoryBreadcrumb(parentCategory[0].name, parentCategory[0].id));
    }
  }

  private get categoryType(): CategoryTypeEnum {
    return this.isParentCategory(this.categoryData)
      ? CategoryTypeEnum.PARENT : this.isSubcategory(this.categoryData)
        ? CategoryTypeEnum.CHILD : CategoryTypeEnum.PROMO;
  }

  private getParentBreadcrumbs(): IBreadcrumb[]  {
    const crumbs: IBreadcrumb[] = [];
    crumbs.push(mainBreadcrumb);
    crumbs.push(new CategoryBreadcrumb(this.categoryData.name, this.categoryData.id));
    return crumbs;
  }

  private getChildBreadcrumbs(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];
    crumbs.push(mainBreadcrumb);
    this.addParentBreadcrumb(crumbs);
    crumbs.push(new CategoryBreadcrumb(this.categoryData.name, this.categoryData.id));
    return crumbs;
  }

  private get categoryBreadcrumbs(): IBreadcrumb[] {
    switch (this.categoryType) {
      case CategoryTypeEnum.PARENT:
        return this.getParentBreadcrumbs();
        break;
      case CategoryTypeEnum.CHILD:
        return this.getChildBreadcrumbs();
        break;
      case CategoryTypeEnum.PROMO:
      default:
        return [];
    }
  }

  private storeBreadcrumbs(): void {
    if (this.searching)
      this.$store.commit('setBreadcrumbs', []);
    else
      this.$store.commit('setBreadcrumbs', this.categoryBreadcrumbs);
  }

  private collectCategoryData(category: ICategory, banner: boolean): void {
    this.categoryData = category;
    this.loadStorageData();
    this.filterData ? this.loadPage() : this.getProducts();
    this.$forceUpdate();
    this.isLoading = false;
    this.storeBreadcrumbs();
  }

  private collectSearchResults(searching: boolean, data: IProducts, query: string): void {
    this.searchQuery = query;
    this.isLoading = true;
    this.searching = searching;
    if (data?.data.length > 0) {
      this.searchResults = data;
      this.searchNotSuccessful = false;
      this.isLoading = false;
      this.displayMainPage = false;
    } else {
      this.searchNotSuccessful = true;
      this.isLoading = false;
      if (searching) {
        this.displayMainPage = false;
      } else {
        this.displayMainPage = true;
      }
    }
  }

  private getFilteredData(filterData: any): void {
    this.isLoadingProducts = true;
    this.currentLoadedPage = 1;
    this.filterData = filterData;
    this.displayFilter = false;
    const type = this.sortFlag === 2 ? 'desc' : (this.sortFlag === 1 ? 'asc' : '');
    this.setStorageData();
    Api.defaultRequest(this.categoryData.id, this.filterData, this.currentLoadedPage, type)
      .then((response) => {
        if (response) {
          this.products = response;
          this.products.currentPage = this.currentLoadedPage;
          this.isLoadingProducts = false;
          if (window.innerWidth > 991) {
            scroll(0,0);
          } else {
            scroll(0,MOBILE_TOP_BAR_HEIGHT);
          }
        }
      });
  }

  private loadPageByType(type: string): void {
    this.currentLoadedPage = type === 'previous'
      ? (this.currentLoadedPage - 1) : (this.currentLoadedPage + 1);
    this.loadPage();
  }

  private loadPage(): void {
    this.isLoading = true;
    const sortType = this.sortFlag === 2 ? 'desc' : (this.sortFlag === 1 ? 'asc' : '');
    this.setStorageData();
    Api.defaultRequest(this.categoryData.id, this.filterData, this.currentLoadedPage, sortType)
      .then((response) => {
        if (response) {
          this.products = response;
          this.products.currentPage = this.currentLoadedPage;
          this.isLoading = false;
          this.isLoadingProducts = false;
          if (window.innerWidth > 991) {
            scroll(0,0);
          } else {
            scroll(0,MOBILE_TOP_BAR_HEIGHT);
          }
        }
      });
  }

  private pageSelect(selectedPage: number) {
    this.currentLoadedPage = selectedPage;
    this.loadPage();
  }

  public changeOrder(type: string, orderFlag: number): void {
    this.isLoading = true;
    Api.defaultRequest(this.categoryData.id, this.filterData, this.currentLoadedPage, type)
      .then((response) => {
        if (response) {
          this.products = response;
          this.products.currentPage = this.currentLoadedPage;
          this.$forceUpdate();
          this.sortFlag = orderFlag;
          this.isLoading = false;
        }
      });
  }

  private loadStorageData(): void {
    const storageData = localStorage.getItem('hc-zdc:catstate');

    if (storageData != null) {

      const categoryState = JSON.parse(storageData);
      if (categoryState[this.categoryData.id]) {
        this.currentLoadedPage = categoryState[this.categoryData.id].currentPage;
        this.filterData = categoryState[this.categoryData.id].filter;
      }
    }
  }

  private setStorageData(): void {

    const storageData = localStorage.getItem('hc-zdc:catstate');
    let categoryState: any = {};

    if (storageData !== null) {
      categoryState = JSON.parse(storageData);
    }

    categoryState[this.categoryData.id] = {
      filter: this.filterData,
      currentPage: this.currentLoadedPage,
      timestamp: Date.now(),
    };

    localStorage.setItem('hc-zdc:catstate', JSON.stringify(categoryState))
  }

  private receiveLoading(loadingLayout: boolean): void {
    this.isLoading = loadingLayout;
  }

  private setLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  private openMobileMenu(): void {
    if (document) {
      (document as any).querySelector('button.navbar-toggler').dispatchEvent(new Event('click'));
    }
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }

  created() {
    if (this.$route.params.query) {
      this.displayMainPage = false;
    }
    this.displayFilter = false;
    setTimeout(()=>scroll(0,0), 300);
  }
}
