import IProducts from '@/module/main/dto/IProducts';
import IProduct from '@/module/main/dto/IProduct';

export default class ProductsDefault implements IProducts {
  constructor(
    public data: IProduct[] = [],
    public pageCount: number = 0,
    public outOf: number = 0,
    public first: number = 0,
    public last: number = 0,
    public currentPage: number = 0,
  ) {}
}
