
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import PagePickerTypes from '@/module/main/model/PagePickerTypesEnum';

@Component
export default class PagePickerItem extends Vue {
  @Prop() private type!: PagePickerTypes;
  @Prop() private pageNumber!: number;

  private pagePickerTypes = PagePickerTypes;
  private etcDefault: string = '...';
  private etcPlus: string  = this.etcDefault;

  private get pageId(): number {
    return this.pageNumber;
  }

  public created(): void {
    this.etcPlus = this.etcDefault;
  }
}
