import IFilter from '@/module/main/dto/IFilter';
import ISeller from '@/module/main/dto/ISeller';
import IBrand from '@/module/main/dto/IBrand';
import ISubCat from '@/module/main/dto/ISubCat';

export default class FilterDefault implements IFilter {
  constructor(
    public sellers: ISeller[] = [],
    public brands: IBrand[] = [],
    public categories: ISubCat[] = [],
    public priceMax: number = 0,
    public priceMin: number = 0,
    public monthlyMax: number = 0,
    public monthlyMin: number = 0,
  ) {}
}
