
import Vue from 'vue';
import VueSlider from 'vue-slider-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Money } from 'v-money';
import IFilter from '@/module/main/dto/IFilter';

@Component({
  components: {
    VueSlider,
    Money,
  },
})
export default class ProductFilter extends Vue {
  @Prop({ type: Boolean, default: true }) private displayFilter!: boolean;
  @Prop() private filterCategoryData!: IFilter;
  @Prop() private filterData!: any;
  @Prop() private categoryId!: number;
  @Prop() private windowWidth?: number;
  private phoneFilterThreshold = 991;

  private priceValue = [
    Math.floor(this.filterCategoryData.priceMin),
    Math.ceil(this.filterCategoryData.priceMax),
  ];

  private priceValueMin = Math.floor(this.filterCategoryData.priceMin);
  private priceValueMax = Math.ceil(this.filterCategoryData.priceMax);
  private priceMin = Math.floor(this.filterCategoryData.priceMin);
  private priceMax = Math.ceil(this.filterCategoryData.priceMax);
  private monthlyValue = [
    Math.floor(this.filterCategoryData.monthlyMin),
    Math.ceil(this.filterCategoryData.monthlyMax),
  ];

  private monthlyMin = Math.floor(this.filterCategoryData.monthlyMin);
  private monthlyMax = Math.ceil(this.filterCategoryData.monthlyMax);
  private monthlyValueMin = Math.floor(this.filterCategoryData.monthlyMin);
  private monthlyValueMax = Math.ceil(this.filterCategoryData.monthlyMax);

  private checkedSellers: number[] = [];
  private checkedBrands: number[] = this.filterData ? this.filterData.brands : [];
  private checkedCategories: number[] = [];

  private moneyFormat = {
    thousands: ' ',
    suffix: ` ${this.$localize('app-currency')}`,
    precision: 0,
    masked: false,
  };

  @Watch('filterCategoryData')
  private onFilterChange(val: IFilter): void {
    this.priceMin = Math.floor(val.priceMin);
    this.priceMax = Math.ceil(val.priceMax);
    this.priceValueMin = Math.floor(val.priceMin);
    this.priceValueMax = Math.ceil(val.priceMax);
    this.monthlyMin = Math.floor(val.monthlyMin);
    this.monthlyMax = Math.ceil(val.monthlyMax);
    this.monthlyValueMin = Math.floor(val.monthlyMin);
    this.monthlyValueMax = Math.ceil(val.monthlyMax);
    this.priceValue = [Math.floor(val.priceMin), Math.ceil(val.priceMax)];
    this.monthlyValue = [Math.floor(val.monthlyMin), Math.ceil(val.monthlyMax)];
    this.checkedSellers = [];
    this.checkedBrands = [];
    this.checkedCategories = [];
  }

  @Watch('filterData')
  private onFilterDataChange(filterData: any): void {
    this.checkedSellers = filterData.sellers;
    this.checkedBrands = filterData.brands;
    this.checkedCategories = filterData.categories;
    this.priceValue = [Math.floor(filterData.priceMin), Math.ceil(filterData.priceMax)];
    this.priceValueMin = Math.floor(filterData.priceMin);
    this.priceValueMax = Math.ceil(filterData.priceMax);
  }

  private inputPriceMinChange(): void {
    if (this.priceMin > this.priceValueMin) {
      this.priceValueMin = this.priceMin;
    } else if (this.priceValueMin > this.priceValue[1]) {
      [, this.priceValueMin] = this.priceValue;
    }
    this.priceValue = [this.priceValueMin, this.priceValueMax];
  }

  private inputPriceMaxChange(): void {
    if (this.priceMax < this.priceValueMax) {
      this.priceValueMax = this.priceMax;
    } else if (this.priceValueMax < this.priceValue[0]) {
      [this.priceValueMin, this.priceValueMax] = this.priceValue;
      this.priceValueMax = this.priceValueMin;
    }
    this.priceValue = [this.priceValueMin, this.priceValueMax];
  }

  private detectChange(): void {
    [this.monthlyValueMin, this.monthlyValueMax] = this.monthlyValue;
    this.monthlyValue = [this.monthlyValueMin, this.monthlyValueMax];
    [this.priceValueMin, this.priceValueMax] = this.priceValue;
    this.priceValue = [this.priceValueMin, this.priceValueMax];
  }

  public getProductsFromFilter(): void {
    const filterData = {
      sellers: this.checkedSellers,
      brands: this.checkedBrands,
      categories: this.checkedCategories,
      priceMin: this.priceValueMin,
      priceMax: this.priceValueMax,
      monthlyMin: this.monthlyValueMin,
      monthlyMax: this.monthlyValueMax,
    };
    this.$emit('collect-filter-data', filterData);
  }

  public cancelFilters(): void {
    const filterData = {
      sellers: [],
      brands: [],
      categories: [],
      priceMin: this.priceMin,
      priceMax: this.priceMax,
      monthlyMin: this.monthlyMin,
      monthlyMax: this.monthlyMax,
    };
    this.checkedSellers = [];
    this.checkedBrands = [];
    this.checkedCategories = [];
    this.priceValueMin = this.priceMin;
    this.priceValueMax = this.priceMax;
    this.monthlyValueMin = this.monthlyMin;
    this.monthlyValueMax = this.monthlyMax;
    this.priceValue = [this.priceMin, this.priceMax];
    this.monthlyValue = [this.monthlyMin, this.monthlyMax];

    this.$emit('collect-filter-data', filterData);
  }
}
