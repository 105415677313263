
import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import PagePickerTypes from '@/module/main/model/PagePickerTypesEnum';
import PagePickerItem from '@/components/PagePickerItem.vue';

const COUNT_LIMIT = 9;

class PickerItem {
  constructor(
    public type: PagePickerTypes,
    public pageNumber: number,
  ) {
  }
}

@Component({
  components: {
    PagePickerItem,
  }
})
export default class ProductPagePicker extends Vue {
  @Prop({type: Number, default: 0}) private pageCount!: number;
  @Prop({type: Number, default: 0}) private selectedPage!: number;

  private items: PickerItem[] = [];

  private get count(): number {
    return this.pageCount;
  }

  private get selected(): number {
    return this.selectedPage;
  }

  private pageSelected(pageNumber: number): void {
    this.$emit('page-select', pageNumber);
  }

  @Watch('selectedPage')
  private onSelectedPage() {
    this.buildItemList();
  }

  @Watch('pageCount')
  private onPageCount() {
    this.buildItemList();
  }

  private toMaxCount(): PickerItem[] {
    const result: PickerItem[] = [];
    for (let pageNumber = 1; pageNumber <= this.count; pageNumber++) {
      result.push(new PickerItem(
        pageNumber != this.selected ? PagePickerTypes.DEFAULT : PagePickerTypes.SELECTED,
        pageNumber
      ));
    }
    return result;
  }

  private defaultSet(): PickerItem[] {
    const result: PickerItem[] = [];

    for (let pageNumber = 1; pageNumber <= 6; pageNumber++) {
      result.push(new PickerItem(
        pageNumber != this.selected ? PagePickerTypes.DEFAULT : PagePickerTypes.SELECTED,
        pageNumber
      ));
    }

    result.push(new PickerItem(PagePickerTypes.ETC, 9));
    result.push(new PickerItem(PagePickerTypes.DEFAULT, this.count));
    result.push(new PickerItem(PagePickerTypes.RIGHT_ARROW, this.selected+1));

    return result;
  }

  private middleSet(): PickerItem[] {
    const result: PickerItem[] = [];

    result.push(new PickerItem(PagePickerTypes.LEFT_ARROW, this.selected-1));
    result.push(new PickerItem(PagePickerTypes.DEFAULT, 1));
    result.push(new PickerItem(PagePickerTypes.ETC, this.selected-4));

    result.push(new PickerItem(PagePickerTypes.DEFAULT, this.selected-1));
    result.push(new PickerItem(PagePickerTypes.SELECTED, this.selected));
    result.push(new PickerItem(PagePickerTypes.DEFAULT, this.selected+1));

    result.push(new PickerItem(PagePickerTypes.ETC, this.selected+4));
    result.push(new PickerItem(PagePickerTypes.DEFAULT, this.count));
    result.push(new PickerItem(PagePickerTypes.RIGHT_ARROW, this.selected+1));

    return result;
  }

  private defaultSetRevert(): PickerItem[] {
    const result: PickerItem[] = [];

    result.push(new PickerItem(PagePickerTypes.LEFT_ARROW, this.selected-1));
    result.push(new PickerItem(PagePickerTypes.DEFAULT, 1));
    result.push(new PickerItem(PagePickerTypes.ETC, this.count-8));

    for (let pageNumber = this.count-5; pageNumber <= this.count; pageNumber++) {
      result.push(new PickerItem(
        pageNumber != this.selected ? PagePickerTypes.DEFAULT : PagePickerTypes.SELECTED,
        pageNumber
      ));
    }

    return result;
  }

  private buildItemList(): void {
    this.items = [];

    if (this.count <= 1) return;
    if (this.count <= COUNT_LIMIT) this.items = this.toMaxCount();
    if (this.count > COUNT_LIMIT) {
      if (this.selected < 6) {
        this.items = this.defaultSet();
      } else if (this.selected > this.count-5) {
        this.items = this.defaultSetRevert();
      } else {
        this.items = this.middleSet();
      }
    }



  }

  public created(): void {
    this.buildItemList();
  }
}
