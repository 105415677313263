import ICategory from '@/module/main/dto/ICategory';
import IFilter from '@/module/main/dto/IFilter';
import FilterDefault from '@/module/main/dto/FilterDefault';
import ISubcategories from '@/module/main/dto/ISubcategories';

export default class CategoryDefault implements ICategory {
  constructor(
    public id: number = 0,
    public name: string = '',
    public priority: number = 0,
    public description: string = '',
    public filterData: IFilter = new FilterDefault(),
    public subcategories: ISubcategories[] = [],
    public parentCategoryId: number = 0,
    public types: string[] = [],
  ) {}
}
